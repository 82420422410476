<template>
    <div class="space-x-3 flex items-center">
        <UiDropdown class="relative">
            <template #trigger>
                <button
                    id="user-menu-button"
                    type="button"
                    class="-m-1.5 flex items-center p-1.5 transition-colors rounded-md hover:bg-slate-50"
                    aria-expanded="false"
                    aria-haspopup="true"
                >
                    <span class="sr-only">Open user menu</span>
                    <UiAvatar
                        class="h-8 w-8"
                        :path="$user.avatar_path"
                    />
                    <div class="hidden lg:flex lg:items-center">
                        <div
                            class="ml-4 text-sm font-semibold leading-6 text-left text-gray-900"
                            aria-hidden="true"
                        >
                            <div>{{ $user.full_name }}</div>
                            <div class="truncate text-xs max-w-[200px] text-gray-400 font-normal">
                                {{ $user.email }}
                            </div>
                        </div>
                        <ChevronDownIconMini class="ml-2 h-5 w-5 text-gray-400" />
                    </div>
                </button>
            </template>
            <template #content>
                <div
                    class="dropdown-menu"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu-button"
                    tabindex="-1"
                >
                    <a
                        id="user-menu-item-0"
                        href="https://abcreche.com/account/profile"
                        target="_blank"
                        class="dropdown-menu-item"
                        role="menuitem"
                        tabindex="-1"
                    >
                        Mon compte
                    </a>
                    <NuxtLink
                        id="user-menu-item-1"
                        to="/auth/logout"
                        class="dropdown-menu-item"
                        role="menuitem"
                        tabindex="-1"
                    >
                        Se déconnecter
                    </NuxtLink>

                    <div class="dropdown-menu-divider" />

                    <UiButtonSlideOver id="changelog">
                        <template #trigger>
                            <button
                                type="button"
                                class="dropdown-menu-item"
                                role="menuitem"
                                tabindex="-1"
                            >
                                Changelog
                            </button>
                        </template>

                        <AppChangelog />
                    </UiButtonSlideOver>

                    <div class="py-1 px-4 text-gray-500 text-xs text-right">
                        Version {{ localVersion }}
                    </div>
                </div>
            </template>
        </UiDropdown>

        <UiButtonSlideOver
            v-if="showUpdateNotification || $pwa.needRefresh"
            id="changelog"
        >
            <template #trigger>
                <button
                    v-tooltip="{ content: 'Une mise à jour est disponible', position: 'left' }"
                    class="group"
                >
                    <ArrowPathIconMini class="size-5 transition-all group-hover:rotate-180 text-orange-500" />
                </button>
            </template>
            <AppChangelog />
        </UiButtonSlideOver>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'UserDropdown',

    data () {
        return {
            showUpdateNotification: false,
            serverVersion: null,
            localVersion: null
        }
    },

    created () {
        this.checkVersion()
        this.localVersion = localStorage.getItem('version')
    },

    methods: {
        checkVersion () {
            fetch('https://abcreche.com/api/v1/versions')
                .then(response => response.json())
                .then((data) => {
                    this.serverVersion = data['app.abcreche.com']

                    // Compare with current version in the localstorage
                    this.localVersion = localStorage.getItem('version')
                    if (this.localVersion !== this.serverVersion) {
                        this.showUpdateNotification = true
                    }

                    setTimeout(() => this.checkVersion(), 5000 * 60) // 5 minutes
                })
                .catch(() => {
                    // might be network error, let's try later
                    setTimeout(() => this.checkVersion(), 5000 * 60) // 5 minutes
                })
        }
    }
})
</script>
