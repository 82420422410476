<template>
    <UiDropdown
        ref="dropdownMenu"
        class="md:relative flex items-center notification-dropdown"
    >
        <template #trigger>
            <button
                type="button"
                class="text-gray-400 relative transition-colors hover:text-gray-500 group"
            >
                <span class="sr-only">View notifications</span>
                <BellIconOutline class="size-6" />
                <span
                    v-if="unreadCount"
                    class="absolute z-10 flex items-center justify-center w-5 h-5 text-white rounded-full -top-3 -right-3 bg-primary-600"
                    style="font-size: 11px; line-height: 8px;"
                >
                    <span class="group-hover:animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-600 opacity-0 group-hover:opacity-45" />
                    {{ unreadCount > 9 ? '9+' : unreadCount }}
                </span>
            </button>
        </template>
        <template #content>
            <div
                class="absolute right-0 z-30 overflow-auto top-14 md:top-10 bg-white border border-gray-200 rounded shadow w-full md:w-[350px]"
                style="max-height:400px"
            >
                <div class="divide-y divide-gray-200">
                    <div
                        v-if="!groupedNotifications.length"
                        class="w-full p-4 space-x-2 text-xs text-center text-gray-300"
                    >
                        Aucune notification
                    </div>
                    <button
                        class="flex items-center justify-end transition-colors w-full px-4 py-2 space-x-2 text-xs text-gray-300 hover:text-primary-600 group hover:bg-primary-50"
                        @click="markAllAsRead"
                    >
                        <span>Marquer tout comme lu</span>
                        <CheckCircleIconOutline class="size-5" />
                    </button>
                    <button
                        v-for="notification in groupedNotifications"
                        :key="notification.id"
                        class="flex space-x-3 w-full p-4 text-left transition-colors group hover:bg-primary-50"
                        :class="{
                            'bg-gray-100 opacity-60': notification.read_at
                        }"
                        @click="notification.clickHandle"
                    >
                        <UiAvatar
                            v-if="notification.data.causer_avatar"
                            class="w-6 h-6 shrink-0"
                            :path="notification.data.causer_avatar"
                        />
                        <img
                            v-else
                            class="w-6 h-6 rounded-full shrink-0"
                            src="/icon.png"
                            alt="ABCreche"
                        >
                        <span class="flex-grow space-y-1 block">
                            <span class="flex items-center justify-between">
                                <span class="text-sm text-gray-900 font-medium">
                                    {{ notification.title }}
                                </span>
                                <span class="text-sm text-gray-500">
                                    {{ $filters.ago(notification.created_at) }}
                                </span>
                            </span>
                            <span class="text-sm block text-gray-500 group-hover:text-gray-800">
                                {{ notification.message }}
                            </span>
                            <span
                                v-if="$user.creches.length > 1 && notification.data.creche"
                                class="text-xs pt-1 block text-gray-400 text-right"
                            >
                                {{ notification.data.creche.name }}
                            </span>
                        </span>
                    </button>
                    <button
                        :class="{ 'loading gray': isLoading }"
                        class="w-full py-1 text-xs text-center text-primary"
                        @click="loadMore"
                    >
                        Voir plus ...
                    </button>
                </div>
            </div>
        </template>
    </UiDropdown>
</template>

<script>
import dayjs from 'dayjs'
import { loadingMixin } from 'abcreche-ui'
import { messageTypes } from '~/const/notifications/notificationTypes'
import NotificationRepository from '~/repositories/Creche/NotificationRepository'
import UserRepository from '~/repositories/Creche/UserRepository.js'

export default {
    mixins: [loadingMixin],

    data () {
        return {
            notifications: [],
            page: 1,
            unreadCount: 0
        }
    },

    computed: {
        groupedNotifications () {
            return this.notifications
                .map((notification) => {
                    const messageType = messageTypes.find(mT => notification.type.includes(mT.type))
                    notification.message = messageType.singleMessage(notification)
                    notification.title = messageType.title(notification)
                    notification.clickHandle = () => {
                        if (notification.data?.creche?.uuid && notification?.data?.creche?.uuid !== this.$user.current_creche_uuid) {
                            const formData = {
                                ...this.$user,
                                current_creche_uuid: notification.data.creche.uuid
                            }
                            this.$dialog.show({
                                title: 'Pour voir cette notification vous devez d\'abord charger de structure',
                                message: 'Voulez-vous passer sur le milieu d\'accueil ' + notification.data.creche.name + ' ?',
                                buttons: [
                                    {
                                        title: 'Continuer',
                                        class: 'btn-primary',
                                        handler: () => {
                                            this.markAsRead(notification)
                                            const link = messageType.link(notification)

                                            UserRepository.update(formData)
                                                .then(() => {
                                                    window.location = link || '/'
                                                })
                                                .catch(error => this.pageError(error))
                                        }
                                    },
                                    { title: 'Annuler' }
                                ]
                            })
                        } else {
                            this.$refs.dropdownMenu.close()
                            this.markAsRead(notification)
                            const link = messageType.link(notification)
                            if (link) {
                                this.$router.push(messageType.link(notification))
                            }
                        }
                    }
                    return notification
                })
        }
    },

    created () {
        this.getNotifications()
    },

    methods: {
        getNotifications () {
            this.load()
            NotificationRepository.getForUser({ page: this.page })
                .then((response) => {
                    this.notifications = [
                        ...this.notifications,
                        ...response.data.data
                    ]
                    this.unreadCount = response.unread_count
                    this.done()
                })
                .catch(error => this.pageError(error))
        },

        markAsRead (notification) {
            NotificationRepository.markAsRead(notification.id)
                .then(() => {
                    notification.read_at = dayjs()
                    if (this.unreadCount > 0) {
                        this.unreadCount--
                    }
                })
                .catch(error => this.pageError(error))
        },

        markAllAsRead () {
            NotificationRepository.markAllAsReadForUser()
                .then(() => {
                    this.page = 1
                    this.notifications = []
                    this.getNotifications()
                })
                .catch(error => this.pageError(error))
        },

        loadMore () {
            this.page++
            this.getNotifications()
        }
    }
}
</script>

<style>
.notification-dropdown > span {
    @apply flex items-center;
}
</style>
