<template>
    <div>
        <div
            v-if="serverVersion === localVersion"
            class="text-sm text-green-600 text-center"
        >
            Votre logiciel est à jour
        </div>
        <div
            v-else
            class="text-sm text-orange-600 hover:underline text-center cursor-pointer"
            @click="updateVersion"
        >
            Mise à jour disponible! Cliquez ici pour rafraîchir
        </div>

        <div
            v-for="date in Object.keys(groupedChangelog)"
            :key="date"
        >
            <h2 class="text-lg font-semibold text-gray-800 mt-5 mb-1 capitalize">
                {{ $filters.format(date, 'dddd DD MMM YYYY') }}
            </h2>
            <ul class="list-disc pl-4 text-gray-600 space-y-1">
                <li
                    v-for="item in groupedChangelog[date]"
                    :key="item.message"
                    class="text-sm"
                >
                    {{ item.message }}
                </li>
            </ul>
        </div>

        <div
            v-if="pagination.last_page > pagination.current_page"
            class="text-center mt-10"
        >
            <button
                class="btn btn-sm"
                :class="{ loading: isLoading }"
                :disabled="isLoading"
                @click="loadMore"
            >
                Charger la suite...
            </button>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { loadingMixin } from 'abcreche-ui'

export default defineComponent({
    name: 'AppChangelog',
    mixins: [loadingMixin],

    data () {
        return {
            changelog: [],
            serverVersion: null,
            localVersion: null,
            pagination: {}
        }
    },

    computed: {
        groupedChangelog () {
            return this.changelog.reduce((acc, item) => {
                const key = item.date
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(item)
                return acc
            }, {})
        }
    },

    created () {
        this.getChangelog()
        this.localVersion = localStorage.getItem('version')
        this.getVersion()
    },

    methods: {
        getChangelog (page = 1) {
            this.load()
            fetch('https://abcreche.com/api/v1/versions/changelog?page=' + page)
                .then(response => response.json())
                .then((data) => {
                    this.done()
                    this.changelog = [...this.changelog, ...data.data]
                    this.pagination = {
                        current_page: data.current_page,
                        last_page: data.last_page
                    }
                })
        },

        getVersion () {
            fetch('https://abcreche.com/api/v1/versions')
                .then(response => response.json())
                .then((data) => {
                    this.serverVersion = data['app.abcreche.com']

                    // Compare with current version in the localstorage
                    this.localVersion = localStorage.getItem('version')
                    if (this.localVersion !== this.serverVersion) {
                        localStorage.setItem('version', this.serverVersion)
                    }
                })
        },

        updateVersion () {
            this.$pwa.updateServiceWorker(true)
            setTimeout(() => {
                window.location.reload()
            }, 400)
        },

        loadMore () {
            this.getChangelog(this.pagination.current_page + 1)
        }
    }
})
</script>
